@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Cormorant Garamond', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f2e9;
  color: #1a1a1a;
  overflow: hidden;
}

/* Cursor effect */
::selection {
  background-color: rgba(160, 140, 110, 0.3);
  color: #1a1a1a;
}

/* Elegant fade in animation */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Subtle breathing animation */
@keyframes breathing {
  0%, 100% { opacity: 0.4; }
  50% { opacity: 0.8; }
}

/* Line draw animation */
@keyframes lineDraw {
  0% { width: 0; }
  100% { width: 100%; }
}

/* Subtle float animation */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}